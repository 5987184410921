import { useState, useLayoutEffect } from "react"

// Hooks for handling screen size changes &
// Detect if window match to media query
// Return boolean
// exemple of use : const isMobile = useMediaQuery("(max-width: 768px)")

export const useMediaQuery = query => {
  const [matches, setMatches] = useState()

  useLayoutEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }

    const onResize = () => {
      setMatches(media.matches)
    }

    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [matches, query])

  return matches
}
