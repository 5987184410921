import React, { createContext, useContext } from "react"
import { useToggle, useMediaQuery } from "../hooks"

const initialState = {
  isMenuOpen: false,
  toggle: () => {},
}

export const MenuContext = createContext(initialState)

const Provider = ({ children }) => {
  const isMobile = useMediaQuery("screen and (max-width: 1007px)")
  const { isToggled, setToggle, toggle } = useToggle(false)
  const closeMenu = () => setToggle(false)

  return (
    <MenuContext.Provider
      value={{
        isMenuOpen: isToggled,
        toggleMenu: toggle,
        closeMenu,
        isMobile,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}

// const MenuProvider = ({ children }) => <Provider children={children} />

export const MenuProvider = ({ element }) => {
  return <Provider children={element} />
}

// export default ({ element }) => {
//   return <MenuProvider>{element}</MenuProvider>
// }

export const useMenuContext = () => {
  return useContext(MenuContext)
}
